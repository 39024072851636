import React from 'react';
import HeaderGr from '../components/layout/HeaderGr';
import Footer from '../components/layout/Footer';

const LayoutDefaultGr = ({ children }) => (
	<>
		<HeaderGr navPosition="right" className="reveal-from-bottom" />
		<main className="site-content">
			{children}
		</main>
		<Footer />
	</>
);

export default LayoutDefaultGr;	
import React from 'react';
// import sections
import HeroGr from '../components/sections/HeroGr';
import Greek from '../components/sections/Greek';


const HomeGr = () => {

	return (
		<>

			<HeroGr className="illustration-section-01" />
			<Greek invertMobile topDivider imageFill className="illustration-section-02" />

		
		</>
	);
}

export default HomeGr;
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
	...SectionSplitProps.types
}

const defaultProps = {
	...SectionSplitProps.defaults
}

const FeaturesSplit = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {

	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const splitClasses = classNames(
		'split-wrap',
		invertMobile && 'invert-mobile',
		invertDesktop && 'invert-desktop',
		alignTop && 'align-top'
	);

	const sectionHeader = {
		title: 'Why Choose Us?'
	};

	return (
		<section
			{...props}
			className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className="center-content" />
					<div className={splitClasses}>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<h3 className="mt-0 mb-12">
								All kinds of Plumbing Installations
									</h3>
									<p className="m-0">
									•Inspection, Maintenance, Renovation of Plumbing Installations<br/>
									• Bathroom and Kitchen Renovations.<br/>
									• Installations of fountains, swimming pools, etc.
                                    Installations, repairs, maintenance and replacements of solar water heaters, electric water heaters, instant water heaters and gas water heaters.<br/>
                                    Our highly trained staff consists of experienced Plumbers - Thermohydraulics.
									</p>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/plumber1.png')}
									alt="Plumbing"
									width={528}
									height={396} />
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">	
								<h3 className="mt-0 mb-12">
								HEATING
									</h3>
								<p className="m-0">
								•New Central Heating Installations with iron, copper and plastic pipes.<br/>
                                •Control, repairs, conversions, network extensions<br/>
                                •Maintenance of Central Heating Installations<br/>
                                •Locating and repairing leaks<br/>
								•Replacement of Akan-type radiator bodies, panel type, aluminum type and towel rail type.<br/>
                                •Single pipe switch replacement, radiator body external loop switch.<br/>
								•Boiler leak repair, pipe leak repair, circulator, burner, chimney, expansion tank and radiator solenoid valve repair or replacement.<br/>
                                •Installation of circulators, automatic fire extinguishers, oil level gauges, electric valves.</p>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/solarheater.png')}
									alt="Solar"
									width={528}
									height={396} />
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<h3 className="mt-0 mb-12">
								DRAINAGE WORKS

									</h3>
								<p className="m-0">
								•Construction of sewage networks for sewage and rain (water).<br/>
                                •Internal, external, vertical and horizontal sewage drainage construction. from plastic, cast iron and lead pipe.<br/>
                                •Construction of wells.<br/>
                                •Installation of simple submersible pumps and with cutters, sewage and rainwater.<br/>
                                •Replacement of drain poles in all types of light fixtures (from cast iron to plastic) with a portable lift<br/>
								• Clogging and Cleaning of wells.
								</p>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/drains.png')}
									alt="Wallet setup"
									width={528}
									height={396} />
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
	);
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
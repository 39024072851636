import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
// import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
	topOuterDivider: PropTypes.bool,
	topDivider: PropTypes.bool
}

const defaultProps = {
	topOuterDivider: false,
	topDivider: false
}

const Footer = ({
	className,
	topOuterDivider,
	topDivider,
	...props
}) => {

	const classes = classNames(
		'site-footer center-content-mobile',
		topOuterDivider && 'has-top-divider',
		className
	);

	return (
		<footer
			{...props}
			className={classes}
		>
			<div className="container">
				<div className={
					classNames(
						'site-footer-inner',
						topDivider && 'has-top-divider'
					)}>
					<div className="footer-top space-between text-xxs">
						<Logo />
						<FooterSocial />
					</div>
				</div>
			</div>
		</footer>
	);
}

/*
					<div className="footer-bottom space-between text-xxs invert-order-desktop">
						<FooterNav />
						<div className="footer-copyright">Satergo Website</div>
					</div>
*/

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
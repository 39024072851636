import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
	...SectionSplitProps.types
}

const defaultProps = {
	...SectionSplitProps.defaults
}

const FeaturesSplit = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {

	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const splitClasses = classNames(
		'split-wrap',
		invertMobile && 'invert-mobile',
		invertDesktop && 'invert-desktop',
		alignTop && 'align-top'
	);

	const sectionHeader = {
		title: 'Γιατί να μας επιλέξετε?'
	};

	return (
		<section
			{...props}
			className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className="center-content" />
					<div className={splitClasses}>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<h3 className="mt-0 mb-12">
								Όλων των ειδών τις Υδραυλικές Εγκαταστάσεις
									</h3>
									<p className="m-0">
									• Έλεγχο, Συντήρηση, Ανακαίνιση Υδραυλικών Εγκαταστάσεων<br/>
									• Ανακαινίσεις Λουτρών και Κουζινών.<br/>
									• Εγκαταστάσεις σιντριβανιών, πισινών κτλ.<br/>
                                    • Τοποθετήσεις, επισκευές, συντηρήσεις και αντικαταστάσεις ηλιακών θερμοσιφώνων , ηλεκτρικών θερμοσιφώνων, ταχυθερμοσιφώνων και θερμοσιφώνων αερίου.
									Το άρτια εκπαιδευμένο προσωπικό μας αποτελείται από έμπειρους Υδραυλικούς - Θερμοϋδραυλικούς
									</p>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/plumber1.png')}
									alt="Plumbing"
									width={528}
									height={396} />
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">	
								<h3 className="mt-0 mb-12">
								ΘΕΡΜΑΝΣΗ
									</h3>
								<p className="m-0">
								• Νέες Εγκαταστάσεις Κεντρικής Θέρμανσης με σιδηροσωλήνες, σωλήνες χαλκού και πλαστικές.<br/>
                                • Έλεγχο, επισκευές, μετατροπές, επεκτάσεις δικτύων<br/>
                                • Συντήρηση Εγκαταστάσεων Κεντρικής Θέρμανσης<br/>
                                • Εντοπισμός και αποκατάσταση διαρροών<br/>
								• Αντικατάσταση σωμάτων καλοριφέρ τύπου ακαν τύπου πάνελ τύπου αλουμινίου και τύπου πετσετοκρεμάστρας.<br/>
                                • Αντικατάσταση διακόπτη μονοσωλήνιου, διακόπτη εξωτερικού βρόγχου σώματος καλοριφέρ.<br/>
								• Επισκευή διαρροής λέβητα , επισκευή διαρροής σωλήνων , επισκευή ή αντικατάσταση κυκλοφορητή, καυστήρα, καμινάδας, δοχείου διαστολής και ηλεκτροβάνας καλοριφέρ.<br/>
                                • Τοποθέτηση κυκλοφορητών , αυτόματων πυροσβεστήρων μετρητών στάθμης πετρελαίου, ηλεκτροβανών.</p>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/solarheater.png')}
									alt="Solar"
									width={528}
									height={396} />
							</div>
						</div>

						<div className="split-item">
							<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
								<h3 className="mt-0 mb-12">
								ΑΠΟΧΕΥΤΕΥΤΙΚΑ ΕΡΓΑ

									</h3>
								<p className="m-0">
								• Κατασκευή δικτύων αποχέτευσης για ακάθαρτα και όμβρια (υδροροή)..<br/>
                                • Κατασκευή αποχετεύσεως ακαθάρτων εσωτερική, εξωτερική, κάθετη και οριζόντια. από σωλήνα πλαστική , μαντεμένια και μολύβδινη.<br/>
                                • Κατασκευή φρεατίων .<br/>
                                • Εγκατάσταση αντλιών απλών υποβρυχίων και με κοπτήρες , λυμάτων και ομβρίων.<br/>
                                • Αντικατάσταση στύλων αποχέτευσης σε όλων των ειδών τους φωταγωγούς (από μαντεμένιους σε πλαστικούς) με φορητό ανελκυστήρα<br/>
								• Αποφράξεις και Καθαρισμός φρεατίων.
								</p>
							</div>
							<div className={
								classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container=".split-item">
								<Image
									src={require('./../../assets/images/drains.png')}
									alt="Wallet setup"
									width={528}
									height={396} />
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
	);
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;